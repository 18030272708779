<template>
  <div class="">
    <v-card
      height="600"
      class="pa-10"
    >
      <v-row>
        <v-col
          cols="12"
          md="9"
          class="mx-auto"
        >
          <v-card-text>
            <v-form>
              <v-img
                max-width="100"
                max-height="100"
                class="mx-auto mb-10"
                src="/avatar.png"
              ></v-img>
              <v-text-field
                v-model="email"
                outlined
                readonly
                label="Email"
                :error="(!!(errors && errors.email))"
                :error-messages="(errors && errors.email ? errors.email: null)"
                :hide-details="!(errors && errors.email)"
                class="mb-5"
                @keyup.enter="submit"
              ></v-text-field>

              <v-text-field
                v-model="full_name"
                outlined
                label="Full Name"
                :error="(!!(errors && errors.full_name))"
                :error-messages="(errors && errors.full_name ? errors.full_name: null)"
                :hide-details="!(errors && errors.full_name)"
                class="mb-5"
                @keyup.enter="submit"
              ></v-text-field>

              <v-text-field
                v-model="new_password"
                outlined
                :type="isPasswordVisible1 ? 'text' : 'password'"
                label="New Password"
                :error="(!!(errors && errors.new_password))"
                :error-messages="(errors && errors.new_password ? errors.new_password: null)"
                :hide-details="!(errors && errors.new_password)"
                :append-icon="isPasswordVisible1 ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                class="mb-5"
                @click:append="isPasswordVisible1 = !isPasswordVisible1"
                @keyup.enter="submit"
              ></v-text-field>

              <v-text-field
                v-model="new_password_confirmation"
                outlined
                :type="isPasswordVisible2 ? 'text' : 'password'"
                label="New Password Confirmation"
                :error="(!!(errors && errors.new_password_confirmation))"
                :error-messages="(errors && errors.new_password_confirmation ? errors.new_password_confirmation: null)"
                :hide-details="!(errors && errors.new_password_confirmation)"
                :append-icon="isPasswordVisible2 ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                @click:append="isPasswordVisible2 = !isPasswordVisible2"
                @keyup.enter="submit"
              ></v-text-field>

              <v-btn
                large
                color="primary"
                class="mt-6 mx-auto d-flex"
                :loading="loading"
                :disabled="loading"
                @click.prevent="submit"
              >
                Update
              </v-btn>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  name: 'Profile',
  data() {
    return {
      isPasswordVisible1: ref(false),
      isPasswordVisible2: ref(false),
      loading: false,
      full_name: '',
      email: this.$store.state.user.email,
      new_password: '',
      new_password_confirmation: '',
      errors: null,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  created() {
    this.full_name = this.$store.state.user.full_name
  },
  methods: {
    submit() {
      this.errors = null
      this.loading = true
      this.$http({
        method: 'post',
        url: '/v1/auth/update-profile',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `${this.$store.state.access.token_type} ${this.$store.state.access.token}`,
        },
        data: {
          full_name: this.full_name,
          new_password: this.new_password,
          new_password_confirmation: this.new_password_confirmation,
        },
      }).then(response => {
        this.$store.commit('SetUser', response.data.user)
        this.$store.commit('SetAccess', response.data.access)
        this.$router.push({ name: 'bots' })
      }).catch(err => {
        this.errors = err.response.data
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
